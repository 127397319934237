<template>
  <pwd-contair>
    <div slot="menu">交易密码设置</div>
    <div slot="pwdContair">
      <div class="pwdContair">
        <div>
          <el-form
            ref="pwdChangeFormRef"
            :rules="pwdChangeFormRule"
            :model="userInfo"
          >
            <el-form-item>
              <el-row :gutter="5">
                <el-col :span="14">
                  <el-input
                    class="input"
                    v-model="userInfo.mobile"
                    placeholder="请输入绑定的手机号码"
                  >
                    <span
                      slot="prefix"
                      class="iconfont icon-shouji loginiconfont"
                    ></span>
                  </el-input>
                </el-col>
                <el-col :span="5">
                  <!-- <el-button
                    type="primary"
                    style="padding:12px 15px"
                    @click="getVerfy()"
                  >
                    获取验证码
                  </el-button> -->
                  <verfiy-btn
                    @sendKey="getKey($event)"
                    :mobile="userInfo.mobile"
                  ></verfiy-btn>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-input v-model="verify" placeholder="请输入验证码">
                <span
                  slot="prefix"
                  class="iconfont icon-yanzhengma loginiconfont"
                ></span>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                show-password
                v-model="withdrawalPwd"
                placeholder="请输入新密码"
              >
                <span
                  slot="prefix"
                  class="iconfont icon-mima loginiconfont"
                ></span>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                show-password
                v-model="pwdComfire"
                placeholder="请再次确定新密码"
              >
                <span
                  slot="prefix"
                  class="iconfont icon-mima loginiconfont"
                ></span>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="btn" type="primary" @click="submit()"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </pwd-contair>
</template>
<script>
import PwdContair from "@/components/supplier/pwdContair/pwdContair";
import { mapGetters } from "vuex";
import {
  checkMobileCodeByKey,
  updateUserWithdrawalPwd,
  updateUser,
} from "@/api/user";
import VerfiyBtn from "@/components/verfiyBtn/verfiyBtn.vue";

export default {
  components: { PwdContair, VerfiyBtn },
  data() {
    return {
      verify: "",
      withdrawalPwd: "",
      pwdComfire: "",
      key: "",
      pwdChangeFormRule: {
        mobile: [
          { required: true, message: "请输入绑定手机号码", trigger: "change" },
        ],
        verify: [
          { required: true, message: "请输入验证码", trigger: "change" },
        ],
        withdrawalPwd: [
          { required: true, message: "请输入新密码", trigger: "change" },
        ],
        pwdComfire: [
          { required: true, message: "请再次确认新密码", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.getKey();
  },
  methods: {
    //获取验证码key
    getKey(val) {
      this.key = val;
      console.log("验证码" + this.key);
    },
    //获取验证码
    // getVerfy() {
    //   getMobileCode(this.userInfo.mobile).then((res) => {
    //     if (res.success) {
    //       this.key = res.data;
    //       console.log(this.key);
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    //提交验证
    submit() {
      //手机验证码
      checkMobileCodeByKey(this.verify, this.key).then((res) => {
        if (res.success) {
          this.$message.success(res.msg);
          if (this.withdrawalPwd == this.pwdComfire) {
            this.userInfo.withdrawalPwd = this.withdrawalPwd;
            console.log(this.userInfo.withdrawalPwd + "和" + this.userInfo.id);
            //更改用户信息
            updateUser(this.userInfo).then((res) => {
              if (res.success) {
                this.$message.success(res.msg);
                // 实时获取用户信息并且更新缓存
                this.$store.dispatch("GetLoginUserInfoByToken");
                // 更改用户提现密码
                updateUserWithdrawalPwd(
                  this.userInfo.id,
                  this.userInfo.withdrawalPwd
                ).then((res) => {
                  if (res.success) {
                    this.$message.success(res.msg);
                    if (this.$route.query.redirect) {
                      this.$router.push({
                        name: this.$route.query.redirect,
                      });
                    } else {
                      if (this.userInfo.roleType == 1) {
                        this.$router.push("/supplier/supplierHome/myAccount");
                      } else if (this.userInfo.roleType == 2) {
                        this.$router.push("/purchaser/purchaserHome/myMoney");
                      }
                    }
                  } else {
                    this.$message.error(res.msg);
                  }
                });
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.$message.error("密码不一致");
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.pwdContair {
  padding: 45px 370px 95px 370px;
  .btn {
    width: 291px;
  }
  .el-input:focus-within {
    span {
      color: #ff5b0c;
    }
  }
}
</style>
